import { Home, Graph, Document } from "react-iconly";

const main = [
  {
    header: "MAIN",
  },
  {
    id: "dashboard",
    title: "Menu",
    icon: <Home set="curved" className="remix-icon" />,
    navLink: "/main/menu",
  },
];

export default main;
