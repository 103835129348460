import React from "react";

import { Card, Col, Row } from "antd";

import PageTitle from "../../layout/components/content/page-title";

export default function Home() {
  return (
    <Row gutter={[32, 32]}>
      <PageTitle
        pageTitle="Dashboard"
      />
    </Row>
  );
}
