import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Form, Input, Button, Checkbox, notification } from "antd";
import LeftContent from "../leftContent";
import {
  RiCloseFill,
  RiCheckboxCircleFill,
  RiErrorWarningFill,
  RiCodeSSlashLine,
} from "react-icons/ri";

// Redux

import { useDispatch } from "react-redux";
import { saveLoginInfo } from "../../../../redux/auth/authActions";
// Services
import { LoginService } from "../../../../services/login";

export default function Login() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = (values) => {
    LoginService(values)
      .then((response) => {
        if (response.data.success === true) {
          dispatch(saveLoginInfo(response.data.data.info));
          history.push("/");
        }
      })
      .catch((error) => {
        if (error.response) {
          openErrorNotification(error.response.data.data.message);
        }
      });
  };

  // Notification
  const openErrorNotification = (error) => {
    notification.open({
      message: "Error",
      description: error,
      icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
      closeIcon: (
        <RiCloseFill className="remix-icon hp-text-color-black-80" size={24} />
      ),
    });
  };
  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={11}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            <h1 className="hp-mb-sm-0">Login</h1>
            <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
              Welcome back, please login to your account.
            </p>

            <Form
              layout="vertical"
              name="login"
              initialValues={{ email: "", password: "", remember: true }}
              className="hp-mt-sm-16 hp-mt-32"
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                label="Username :"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your email!",
                  },
                ]}
                className="hp-mb-16"
              >
                <Input id="error" />
              </Form.Item>

              <Form.Item
                label="Password :"
                name="password"
                className="hp-mb-8"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password id="warning2" />
              </Form.Item>

              <Row align="middle" justify="space-between">
                <Form.Item className="hp-mb-0">
                  <Checkbox name="remember">Remember me</Checkbox>
                </Form.Item>

                <Link
                  className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                  to="/pages/authentication/recover-password"
                >
                  Forgot Password?
                </Link>
              </Row>

              <Form.Item className="hp-mt-16 hp-mb-8">
                {/* <Link to="/"> */}
                <Button block type="primary" htmlType="submit">
                  Sign in
                </Button>
                {/* </Link> */}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
