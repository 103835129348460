import { combineReducers } from "redux";

import customiseReducer from "./customise/customiseReducer";
import authReducer from "./auth/authReducer";
import menuReducer from "./menu/menuReducer";
import homeReducer from "./home/homeReducer";
import splashReducer from "./splash/splashReducer";
import ctaReducer from "./cta/ctaReducer";
import sliderReducer from "./slider/sliderReducer";
import projectsReducer from "./projects/projectsReducer";
import aboutUsReducer from "./aboutUs/aboutUsReducer";
import hostingReducer from './solutions/hosting/hostingReducer';
import hubspotReducer from "./solutions/hubspot/hubspotReducer";
import sustainabilityReducer from './solutions/sustainability/sustainabilityReducer';
import smartBuildingReducer from "./solutions/smart-building/smartBuildingReducer";
import servicesReducer from "./services/servicesReducer";
import contactUsReducer from "./contactUs/contactUsReducer";
import joinUsReducer from "./joinUs/joinUsReducer";
import privacyReducer from "./privacy/privacyReducer";
import termsReducer from "./terms/termsReducer";

const rootReducer = combineReducers({
  customise: customiseReducer,
  auth: authReducer,
  menu: menuReducer,
  splash: splashReducer,
  cta: ctaReducer,
  slider: sliderReducer,
  home: homeReducer,
  services: servicesReducer,
  projects: projectsReducer,
  aboutUs: aboutUsReducer,
  smartbuilding: smartBuildingReducer,
  sustainability: sustainabilityReducer,
  hubspot: hubspotReducer,
  hosting: hostingReducer,
  contactUs: contactUsReducer,
  joinUs: joinUsReducer,
  privacy: privacyReducer,
  terms: termsReducer
});

export default rootReducer;
