import { Home, CloseSquare, PaperPlus } from "react-iconly";

const pages = [
  {
    header: "PAGES",
  },
  {
    id: "splash",
    title: "Splash",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/splash",
  },
  {
    id: "cta",
    title: "Cta",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/cta",
  },
  {
    id: "slider",
    title: "Slider",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/slider",
  },

  {
    id: "home-page",
    title: "Home Page",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/home-page",
  },
  {
    id: "services",
    title: "How We Do It",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/how-we-do-it",
  },
  {
    id: "projects",
    title: "Projects",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/projects",
  },
  {
    id: "about-us",
    title: "About Us",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/about-us",
  },
  {
    id: "solutions",
    title: "Solutions",
    icon: <CloseSquare set="curved" className="remix-icon" />,
    children: [
      {
        id: "smart-building",
        title: "Smart Building",
        navLink: "/pages/solutions/smart-building",
      },
      {
        id: "sustainability",
        title: "Sustainability",
        navLink: "/pages/solutions/sustainability",
      },
      {
        id: "hubspot",
        title: "Hubspot",
        navLink: "/pages/solutions/hubspot",
      },
      {
        id: "hosting",
        title: "Hosting",
        navLink: "/pages/solutions/hosting",
      },
    ],
  },
  {
    id: "contact-us",
    title: "Contact Us",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/contact-us",
  },
  {
    id: "join-us",
    title: "Join Us",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/join-us",
  },
  {
    id: "privacy",
    title: "Privacy Policy",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/privacy",
  },
  {
    id: "terms",
    title: "Terms & Conditions",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/pages/terms",
  },
  // {
  //   id: "blank-page",
  //   title: "Blank Page",
  //   icon: <Home set="curved" className="remix-icon" />,
  //   navLink: "/pages/blank-page",
  // },
  // {
  //   id: "errors",
  //   title: "Error Pages",
  //   icon: <CloseSquare set="curved" className="remix-icon" />,
  //   children: [
  //     {
  //       id: "error-page",
  //       title: "404",
  //       navLink: "/pages/error-page",
  //     },
  //   ],
  // },
];

export default pages;
