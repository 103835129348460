const initialState = {
  allData: [],
  data: [],
  total: 1,
  //   params: {},
  selectedCta: null,
};

const ctaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CTA":
      return { ...state, allData: action.data };

    // case "GET_DATA":
    //   return {
    //     ...state,
    //     data: action.data,
    //     total: action.totalPages,
    //     params: action.params,
    //   };

    case "GET_CTA":
      return { ...state, selectedCta: action.data };

    case "ADD_CTA":
      return { ...state };

    case "UPDATE_CTA":
      return { ...state };

    case "DELETE_CTA":
      return { ...state };

    default:
      return { ...state };
  }
};

export default ctaReducer;
