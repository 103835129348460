import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/pages/splash",
    component: lazy(() => import("../../view/pages/splash")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/cta",
    component: lazy(() => import("../../view/pages/cta")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/slider",
    component: lazy(() => import("../../view/pages/slider")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/home-page",
    component: lazy(() => import("../../view/pages/home")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/how-we-do-it",
    component: lazy(() => import("../../view/pages/services")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/projects",
    component: lazy(() => import("../../view/pages/projects")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/about-us",
    component: lazy(() => import("../../view/pages/about-us")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/projects/project-detail",
    component: lazy(() => import("../../view/pages/projects")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/solutions/smart-building",
    component: lazy(() => import("../../view/pages/solutions/smart-building")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/solutions/sustainability",
    component: lazy(() => import("../../view/pages/solutions/sustainability")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/solutions/hubspot",
    component: lazy(() => import("../../view/pages/solutions/hubspot")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/solutions/hosting",
    component: lazy(() => import("../../view/pages/solutions/hosting")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/contact-us",
    component: lazy(() => import("../../view/pages/contact-us")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/join-us",
    component: lazy(() => import("../../view/pages/join-us")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/privacy",
    component: lazy(() => import("../../view/pages/privacy")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/terms",
    component: lazy(() => import("../../view/pages/terms")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/error-page",
    component: lazy(() => import("../../view/pages/error")),
    layout: "FullLayout",
  },
  {
    path: "/pages/authentication/login",
    component: lazy(() => import("../../view/pages/authentication/login")),
    layout: "FullLayout",
  },

  // Main
  {
    path: "/main/menu",
    component: lazy(() => import("../../view/main/menu")),
    layout: "VerticalLayout",
  },
];

export default PagesRoutes;
